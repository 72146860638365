import { ActivationFinished } from "./ActivationFinished"
import { requestActivateUser } from "../../hooks/useUsers"
import { useCallback, useState } from "react"
import { useQuery } from "../../hooks/useQuery"
import { useNavigate } from "react-router-dom"

export const useUserActivationForm = () => {
    const [fetching, setFetching] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const query = useQuery()
    const activationToken = query.get("activation_token") || ""
    const userId = query.get("user_id") || ""

    const navigate = useNavigate()

    const handleSubmit = useCallback(async () => {
        setFetching(true)
        const { error } = await requestActivateUser(userId, activationToken)
        setFetching(false)

        if (error) {
            const merror = error.parse?.()
            switch (merror?.["errorType"]) {
                case "invalid_activation_token":
                    setErrorMessage("無効な有効化URLです。")
                    break
                case "activation_token_expired":
                    setErrorMessage(
                        "この有効化URLはすでに有効期限が切れています。管理者にお問い合わせください。"
                    )
                    break
                case "invalid_activation_status":
                    setErrorMessage("このユーザーはすでに無効化されています。")
                    break
                default:
                    setErrorMessage(
                        `不明なエラーが発生しました。 (${error.message})`
                    )
                    break
            }
        } else {
            navigate(ActivationFinished.path)
        }
    }, [userId, activationToken, navigate])

    const handleClearError = useCallback(() => {
        setErrorMessage("")
    }, [])

    return {
        fetching,
        handleSubmit,
        handleClearError,
        errorMessage,
    }
}
