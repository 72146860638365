import { ResetPasswordFinished } from "./ResetPasswordFinished"
import { requestResetPassword } from "../../hooks/useUsers"
import { useCallback, useState } from "react"
import { useQuery } from "../../hooks/useQuery"
import { useNavigate } from "react-router-dom"

export const useResetPassword = () => {
    const [fetching, setFetching] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [newPassword, setNewPassword] = useState("")

    const navigate = useNavigate()

    const query = useQuery()
    const resetToken = query.get("reset_token") || ""
    const userId = query.get("user_id") || ""

    const handleSubmit = useCallback(async () => {
        setFetching(true)
        const { error } = await requestResetPassword(
            resetToken,
            userId,
            newPassword
        )
        setFetching(false)

        if (error) {
            const merror = error.parse?.()
            switch (merror?.["errorType"]) {
                case "invalid_reset_token":
                    setErrorMessage("無効なパスワード再設定URLです。")
                    break
                case "reset_token_expired":
                    setErrorMessage(
                        "このパスワード再設定URLはすでに有効期限が切れています。最初からやり直してください。"
                    )
                    break
                default:
                    setErrorMessage(
                        `不明なエラーが発生しました。 (${error.message})`
                    )
                    break
            }
        } else {
            navigate(ResetPasswordFinished.path)
        }
    }, [resetToken, userId, newPassword, navigate])

    const handleClearError = useCallback(() => {
        setErrorMessage("")
    }, [])

    return {
        fetching,
        handleSubmit,
        handleClearError,
        errorMessage,
        newPassword,
        setNewPassword,
    }
}
