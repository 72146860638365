import { css } from "@emotion/react"
import { Button, Modal, Typography } from "matsuri-ui"
import { useUserActivationForm } from "./useActivationForm"

const sx = {
    root: {
        padding: "1vh 4vw",
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        paddingTop: "2em",
        paddingBottom: "2em",
    },
    grow: {
        flexGrow: 1,
    },
    modal: {
        width: "90%",
    },
    noshrink: {
        flexShrink: 0,
    },
} as const

export const ActivationForm: Page = () => {
    const { fetching, errorMessage, handleSubmit, handleClearError } =
        useUserActivationForm()

    return (
        <>
            <h2>Activation</h2>
            {errorMessage && (
                <Modal
                    style={sx.modal}
                    body={
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 16px;
                            `}
                        >
                            <Typography
                                color="error"
                                variant="h4"
                                align="center"
                            >
                                ERROR
                            </Typography>
                            <Typography>{errorMessage}</Typography>
                        </div>
                    }
                    footer={
                        <Button
                            variant="filled"
                            color="primary"
                            fullWidth
                            onClick={handleClearError}
                        >
                            OK
                        </Button>
                    }
                    backdrop
                />
            )}
            <Button variant="filled" onClick={handleSubmit} disabled={fetching}>
                こちらをクリックし、m2mのユーザーを利用可能にしてください
            </Button>
        </>
    )
}

ActivationForm.path = "/activation_form"
