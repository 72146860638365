import { ResetPasswordRequestFinished } from "./ResetPasswordRequestFinished"
import { requestSendResetPasswordEmail } from "./../../hooks/useUsers"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

export const useResetPasswordRequest = () => {
    const [fetching, setFetching] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [email, setEmail] = useState("")

    const navigate = useNavigate()

    const handleSubmit = useCallback(async () => {
        setFetching(true)
        const { error } = await requestSendResetPasswordEmail(email)
        setFetching(false)
        if (error) {
            const merror = error.parse?.()
            switch (merror?.["errorType"]) {
                case "user_not_found":
                    setErrorMessage(
                        "このメールアドレスは登録されていません。メールアドレスを確認してください。"
                    )
                    break
                default:
                    setErrorMessage(
                        `不明なエラーが発生しました。 (${error.message})`
                    )
                    break
            }
        } else {
            navigate(ResetPasswordRequestFinished.path)
        }
    }, [email, navigate])

    const handleClearError = useCallback(() => {
        setErrorMessage("")
    }, [])

    return {
        fetching,
        handleSubmit,
        handleClearError,
        errorMessage,
        email,
        setEmail,
    }
}
